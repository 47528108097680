@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Tangerine:wght@700&display=swap');

* {
  margin: 0px;
  padding: 0px;
}
html {
  /* background-color: #ffc754; */
  background-color: black;
}
body {
  margin: 0px;
  padding: 0px;
}

.MuiDrawer-paperAnchorLeft {
  background-color: black !important;
}


@media screen and (max-width: 1280px) {
  .project-display {
    flex-direction: column !important;
    max-width: 370px !important;
  }
  .project-description {
    width: 100% !important;
  }
  .video {
    display: none !important;
  }
  .video-mobile {
    display: inline !important;
    
  }
  .video-mobile>a {
    text-decoration: none !important;
    color: white;
  }
  
}
@media screen and (max-width: 760px) {
  .core-values {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .value {
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
    padding: 1vh 10vw !important;
    width: 100% !important;
  }

}

/* Card flip in projects page */
.flip-card {
  background-color: transparent;
  width: 57px;
  height: 57px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
  border-radius: 8px;
}

.flip-card-back {
  background-color: #42414d;
  color: white;
  transform: rotateY(180deg);
  border: 1px solid #b6b6bb;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}